// Converted Variables

// Custom Media Query Variables

/*

   TYPE SCALE
   Docs: http://tachyons.io/docs/typography/scale/

   Base:
    f = font-size

   Modifiers
     1 = 1st step in size scale
     2 = 2nd step in size scale
     3 = 3rd step in size scale
     4 = 4th step in size scale
     5 = 5th step in size scale
     6 = 6th step in size scale
     7 = 7th step in size scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large
*/

/*
 * For Hero/Marketing Titles
 *
 * These generally are too large for mobile
 * so be careful using them on smaller screens.
 * */

.f-6,
.f-headline {
  font-size: map-get($type-scale, h1);
}
.f-5,
.f-subheadline {
  font-size: map-get($type-scale, h2);
}
.f-4 {
  font-size: map-get($type-scale, h3);
}

/* Type Scale */

.f1 {
  font-size: map-get($type-scale, f1);
}
.f2_5 {
  font-size: 2.75rem;
}
.f2 {
  font-size: map-get($type-scale, f2);
}
.f3 {
  font-size: map-get($type-scale, f3);
}
.f4 {
  font-size: map-get($type-scale, f4);
}
.f5_5 {
  font-size: 1.125rem;
}
.f5 {
  font-size: map-get($type-scale, f5);
}
.f6 {
  font-size: map-get($type-scale, f6);
}
.f7 {
  font-size: map-get($type-scale, f7);
} /* Small and hard to read for many people so use with extreme caution */

@media #{$breakpoint-not-small} {
  .f-6-ns,
  .f-headline-ns {
    font-size: map-get($type-scale, h1);
  }
  .f-5-ns,
  .f-subheadline-ns {
    font-size: map-get($type-scale, h2);
  }
  .f1-ns {
    font-size: map-get($type-scale, f1);
  }
  .f2_5-ns {
    font-size: 2.75rem;
  }
  .f2-ns {
    font-size: map-get($type-scale, f2);
  }
  .f3-ns {
    font-size: map-get($type-scale, f3);
  }
  .f4-ns {
    font-size: map-get($type-scale, f4);
  }
  .f5-ns {
    font-size: map-get($type-scale, f5);
  }
  .f6-ns {
    font-size: map-get($type-scale, f6);
  }
  .f7-ns {
    font-size: map-get($type-scale, f7);
  }
}

@media #{$breakpoint-medium} {
  .f-6-m,
  .f-headline-m {
    font-size: map-get($type-scale, h1);
  }
  .f-5-m,
  .f-subheadline-m {
    font-size: map-get($type-scale, h2);
  }
  .f1-m {
    font-size: map-get($type-scale, f1);
  }
  .f2-m {
    font-size: map-get($type-scale, f2);
  }
  .f3-m {
    font-size: map-get($type-scale, f3);
  }
  .f4-m {
    font-size: map-get($type-scale, f4);
  }
  .f5-m {
    font-size: map-get($type-scale, f5);
  }
  .f6-m {
    font-size: map-get($type-scale, f6);
  }
  .f7-m {
    font-size: map-get($type-scale, f7);
  }
}

@media #{$breakpoint-large} {
  .f-6-l,
  .f-headline-l {
    font-size: map-get($type-scale, h1);
  }
  .f-5-l,
  .f-subheadline-l {
    font-size: map-get($type-scale, h2);
  }
  .f1-l {
    font-size: map-get($type-scale, f1);
  }
  .f2-l {
    font-size: map-get($type-scale, f2);
  }
  .f3-l {
    font-size: map-get($type-scale, f3);
  }
  .f4-l {
    font-size: map-get($type-scale, f4);
  }
  .f5-l {
    font-size: map-get($type-scale, f5);
  }
  .f6-l {
    font-size: map-get($type-scale, f6);
  }
  .f7-l {
    font-size: map-get($type-scale, f7);
  }
}

@media #{$breakpoint-large2} {
  .f1-l2 {
    font-size: map-get($type-scale, f1);
  }
}
