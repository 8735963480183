
// Converted Variables


// Custom Media Query Variables


/*

  BOX SIZING

*/

.border-box {
  box-sizing: border-box;
}
.content-box {
	box-sizing: content-box;
}

