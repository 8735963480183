
// Converted Variables


// Custom Media Query Variables


/*

  STYLES

  Add custom styles here.

*/
html {
  font-size:100%;
}
::-moz-selection { background-color: $subtle-blue; }
::selection { background-color: $subtle-blue; }
h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

a:focus,
button:focus {
  outline-color:$blue;
  box-shadow: 0 0 1px 1px $mid-gray;
}

