
// Converted Variables


// Custom Media Query Variables


/*

   MIN WIDTHS

   Base:
     minw = min-width

   Modifiers
     1 = 1st step in width scale
     2 = 2nd step in width scale
     3 = 3rd step in width scale
     4 = 4th step in width scale
     5 = 5th step in width scale
     6 = 6st step in width scale
     7 = 7nd step in width scale
     8 = 8rd step in width scale
     9 = 9th step in width scale

     -100 = literal value 100%

     -none  = string value none


   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

/* Min Width Custom */

.minw_800 { min-width: 50rem; }
.minw_1030 { min-width: 64.375rem; }

/* Min Width Percentages */

.minw-100  { min-width: 100%; }

/* Min Width Scale */

.minw1  {  min-width: 1rem; }
.minw2  {  min-width: 2rem; }
.minw3  {  min-width: 4rem; }
.minw3_5{  min-width: 6rem; }
.minw3_75{ min-width: 7rem; }
.minw4  {  min-width: 8rem; }
.minw4_25{ min-width: 10rem; }
.minw4_5 { min-width: 12rem; }
.minw5  {  min-width: 16rem; }
.minw5_15 {  min-width: 18rem; }
.minw5_25{ min-width: 20rem; }
.minw5_5 { min-width: 24rem; }
.minw6  {  min-width: 32rem; }
.minw7  {  min-width: 48rem; }
.minw8  {  min-width: 64rem; }
.minw9  {  min-width: 96rem; }

/* Min Width String Properties */

.minw-none { min-width: none; }

@media #{$breakpoint-not-small} {
  .minw-100-ns  { min-width: 100%; }

  .minw1-ns  {  min-width: 1rem; }
  .minw2-ns  {  min-width: 2rem; }
  .minw3-ns  {  min-width: 4rem; }
  .minw4-ns  {  min-width: 8rem; }
  .minw5-ns  {  min-width: 16rem; }
  .minw6-ns  {  min-width: 32rem; }
  .minw7-ns  {  min-width: 48rem; }
  .minw8-ns  {  min-width: 64rem; }
  .minw9-ns  {  min-width: 96rem; }

  .minw-none-ns { min-width: none; }
}

@media #{$breakpoint-medium} {
  .minw-100-m  { min-width: 100%; }

  .minw1-m  {  min-width: 1rem; }
  .minw2-m  {  min-width: 2rem; }
  .minw3-m  {  min-width: 4rem; }
  .minw4-m  {  min-width: 8rem; }
  .minw5-m  {  min-width: 16rem; }
  .minw6-m  {  min-width: 32rem; }
  .minw7-m  {  min-width: 48rem; }
  .minw8-m  {  min-width: 64rem; }
  .minw9-m  {  min-width: 96rem; }

  .minw-none-m { min-width: none; }
}

@media #{$breakpoint-large} {
  .minw-100-l  { min-width: 100%; }

  .minw1-l  {  min-width: 1rem; }
  .minw2-l  {  min-width: 2rem; }
  .minw3-l  {  min-width: 4rem; }
  .minw4-l  {  min-width: 8rem; }
  .minw5-l  {  min-width: 16rem; }
  .minw6-l  {  min-width: 32rem; }
  .minw7-l  {  min-width: 48rem; }
  .minw8-l  {  min-width: 64rem; }
  .minw9-l  {  min-width: 96rem; }

  .minw-none-l { min-width: none; }
}
