
// Converted Variables


// Custom Media Query Variables


/*

   FORMS

*/

.input-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.button-reset::-moz-focus-inner,
.input-reset::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.text-input {
	border:0;
	box-shadow: 0 0 0 1px $med-dark-gray;
	transition: box-shadow .1s ease-out;
  transform: translateZ(0);
	&:focus {
	  outline: 0;
	  box-shadow: 0 0 4px 1px $blue;
	}
}
.text-input::-webkit-input-placeholder {
  color: $med-dark-gray;
}
.text-input::-moz-placeholder {
  color: $med-dark-gray;
}
.text-input:-ms-input-placeholder {
  color: $med-dark-gray;
}
