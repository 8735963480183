
// Converted Variables


// Custom Media Query Variables


/*

   SKINS
   Docs: http://tachyons.io/docs/themes/skins/

   Classes for setting foreground and background colors on elements.
   If you haven't declared a border color, but set border on an element, it will
   be set to the current text color.

*/

/* Text colors */
.black {         color: $black; }
.dark-gray {     color: $dark-gray; }
.med-dark-gray { color:	$med-dark-gray; }
.mid-gray {      color: $mid-gray; }
.light-gray {		 color: $light-gray; }
.white {         color: $white; }

.red { color: $red; }
.yellow { color: $yellow; }
.green { color: $green; }
.blue { color: $blue; }
.subtle-blue { color: $subtle-blue; }
.subtle-green { color: $subtle-green; }
.subtle-yellow { color: $subtle-yellow; }
.dark-subtle-yellow { color: $dark-subtle-yellow; }
.subtle-red { color: $subtle-red; }
.color-inherit { color: inherit; }

.mrets-blue { color: $mrets-blue; }
.mrets-green { color: $mrets-green; }

.chart-dark-green {    color: $chart-dark-green; }
.chart-med-dark-green {color: $chart-med-dark-green; }
.chart-mid-green {     color: $chart-mid-green; }
.chart-light-green {   color: $chart-light-green; }

.chart-dark-orange {    color: $chart-dark-orange; }
.chart-med-dark-orange {color: $chart-med-dark-orange; }
.chart-mid-orange {     color: $chart-mid-orange; }
.chart-light-orange {   color: $chart-light-orange; }

.chart-dark-blue {    color: $chart-dark-blue; }
.chart-med-dark-blue {color: $chart-med-dark-blue; }
.chart-mid-blue {     color: $chart-mid-blue; }
.chart-light-blue {   color: $chart-light-blue; }

/* Background colors */

.bg-white-o-80 {    background-color: rgba(255, 255, 255, 0.8); }

.bg-black {         background-color: $black; }
.bg-dark-gray {     background-color: $dark-gray; }
.bg-med-dark-gray { background-color:	$med-dark-gray; }
.bg-mid-gray {      background-color: $mid-gray; }
.bg-light-gray {		background-color: $light-gray; }
.bg-white {					background-color: $white; }
.bg-transparent {   background-color: $transparent; }

.bg-red { background-color: $red; }
.bg-yellow { background-color: $yellow; }
.bg-green { background-color: $green; }
.bg-blue { background-color: $blue; }
.bg-subtle-blue { background-color: $subtle-blue; }
.bg-subtle-green { background-color: $subtle-green; }
.bg-subtle-yellow { background-color: $subtle-yellow; }
.bg-subtle-red { background-color: $subtle-red; }
.bg-inherit { background-color: inherit; }

.bg-mrets-blue { background-color: $mrets-blue; }
.bg-mrets-green { background-color: $mrets-green; }

.bg-chart-dark-green {    background-color: $chart-dark-green; }
.bg-chart-med-dark-green {background-color: $chart-med-dark-green; }
.bg-chart-mid-green {     background-color: $chart-mid-green; }
.bg-chart-light-green {   background-color: $chart-light-green; }

.bg-chart-dark-orange {    background-color: $chart-dark-orange; }
.bg-chart-med-dark-orange {background-color: $chart-med-dark-orange; }
.bg-chart-mid-orange {     background-color: $chart-mid-orange; }
.bg-chart-light-orange {   background-color: $chart-light-orange; }

.bg-chart-dark-blue {    background-color: $chart-dark-blue; }
.bg-chart-med-dark-blue {background-color: $chart-med-dark-blue; }
.bg-chart-mid-blue {     background-color: $chart-mid-blue; }
.bg-chart-light-blue {   background-color: $chart-light-blue; }
