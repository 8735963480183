// Converted Variables

// Custom Media Query Variables

/*

   LETTER SPACING
   Docs: http://tachyons.io/docs/typography/tracking/

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

$letter-spacing-subtle: 0.03125em;
$letter-spacing: 0.0625em;
$letter-spacing-tight: -0.05em;
$letter-spacing-mega: 0.25em;

.tracked-subtle {
  letter-spacing: 0.03125em;
}
.tracked {
  letter-spacing: 0.0625em;
}
.tracked-tight {
  letter-spacing: -0.05em;
}
.tracked-mega {
  letter-spacing: 0.25em;
}

@media #{$breakpoint-not-small} {
  .tracked-subtle-ns {
    letter-spacing: 0.03125em;
  }
  .tracked-ns {
    letter-spacing: 0.0625em;
  }
  .tracked-tight-ns {
    letter-spacing: -0.05em;
  }
  .tracked-mega-ns {
    letter-spacing: 0.25em;
  }
}

@media #{$breakpoint-medium} {
  .tracked-subtle-m {
    letter-spacing: 0.03125em;
  }
  .tracked-m {
    letter-spacing: 0.0625em;
  }
  .tracked-tight-m {
    letter-spacing: -0.05em;
  }
  .tracked-mega-m {
    letter-spacing: 0.25em;
  }
}

@media #{$breakpoint-large} {
  .tracked-subtle-l {
    letter-spacing: 0.03125em;
  }
  .tracked-l {
    letter-spacing: 0.0625em;
  }
  .tracked-tight-l {
    letter-spacing: -0.05em;
  }
  .tracked-mega-l {
    letter-spacing: 0.25em;
  }
}
