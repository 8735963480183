@import '../helpers';

body {
  font-family: $nunito-sans;
}

#root {
  font-family: getFontFamily();
}

button {
  cursor: pointer;
}

div.Mui-error {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    border: $spacing-small solid transparent;
    border-color: $red $red transparent transparent;
    top: 0;
    right: 0;
  }
}

label div.Mui-error::after {
  content: none;
  display: none;
}

.MuiDialog-root .MuiBackdrop-root {
  background: rgba(12, 24, 39, 0.75);
}

body .MRetsModal__Title.MuiDialogTitle-root,
body .TableColumnsModal__Title.MuiDialogTitle-root {
  background-color: #2c405a;
  color: #ffffff;
  padding: 15px 30px;
}

.MuiDialogTitle-root h2,
h2.MuiTypography-root.MuiTypography-h6 {
  color: #ffffff;
  font-size: 1em;
}

.MuiDialog-root .MuiDialog-paper > button {
  top: 0.5rem;
}

.MuiDialog-root .MuiDialog-paper > button .MuiSvgIcon-root {
  color: #ffffff;
  fill: #ffffff;
}

.App {
  .MuiStepIcon-root {
    color: transparent;
    border: 2px solid #8c9eb6;
    border-radius: 50%;
    font-size: 2rem;
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    color: #2c405a;
    border: none;
  }

  .MuiStepIcon-root.MuiStepIcon-active .MuiStepIcon-text {
    fill: #fff;
  }

  .MuiStepLabel-label {
    color: #8c9eb6;
  }

  .MuiStepIcon-text {
    fill: #8c9eb6;
  }

  .MuiStepLabel-label.MuiStepLabel-active {
    color: #2c405a;
  }

  .MuiStepConnector-lineHorizontal {
    border-top-width: 2px;
  }

  .MuiStepConnector-line {
    border-color: #8c9eb6;
  }

  .MuiStepConnector-alternativeLabel {
    top: 15px;
  }

  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #8c9eb6;
    border: none;
  }

  .MuiInputBase-input {
    font-size: getTypeScale(f6);
  }

  .MuiChip-outlinedPrimary {
    color: $dark-gray;
    border-color: $dark-gray;
  }

  .TableHeader__Chip svg {
    fill: $med-dark-gray;
    opacity: 1;
  }

  .AccountShow__Card {
    background-color: transparent;
    border: none;
  }
}
