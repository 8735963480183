
// Converted Variables


// Custom Media Query Variables


/*

   LINKS
   Docs: http://tachyons.io/docs/elements/links/

*/

.link {
  text-decoration: underline;
  transition: color .15s ease-in;
  &.blue {
    color:#0067EB;
  }
}

.link:link,
.link:visited {
  transition: color .15s ease-in;
}
.link:hover   {
	text-decoration: none;
  transition: color .15s ease-in;
}
.link:active  {
  transition: color .15s ease-in;
}
.link:focus   {
	text-decoration: none;
  transition: color .15s ease-in;
  outline: 1px dotted currentColor;
}
