
// Converted Variables


// Custom Media Query Variables


/*

   MIN HEIGHTS

   Base:
     minh = min-height

   Modifiers
     1 = 1st step in height scale
     2 = 2nd step in height scale
     3 = 3rd step in height scale
     4 = 4th step in height scale
     5 = 5th step in height scale
     6 = 6st step in height scale
     7 = 7nd step in height scale
     8 = 8rd step in height scale
     9 = 9th step in height scale

     -100 = literal value 100%

     -none  = string value none


   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

/* Min Height Percentages */

.minh-100  { min-height: 100%; }

/* Min Height Scale */

.minh1  {  min-height: 1rem; }
.minh2  {  min-height: 2rem; }
.minh2-5  {  min-height: 3rem; }
.minh3  {  min-height: 4rem; }
.minh4  {  min-height: 8rem; }
.minh4_75  {  min-height: 13rem; }
.minh5  {  min-height: 16rem; }
.minh6  {  min-height: 32rem; }
.minh7  {  min-height: 48rem; }
.minh8  {  min-height: 64rem; }
.minh9  {  min-height: 96rem; }

/* Min Height String Properties */

.minh-none { min-height: none; }

@media #{$breakpoint-not-small} {
  .minh-100-ns  { min-height: 100%; }

  .minh1-ns  {  min-height: 1rem; }
  .minh2-ns  {  min-height: 2rem; }
  .minh3-ns  {  min-height: 4rem; }
  .minh4-ns  {  min-height: 8rem; }
  .minh5-ns  {  min-height: 16rem; }
  .minh6-ns  {  min-height: 32rem; }
  .minh7-ns  {  min-height: 48rem; }
  .minh8-ns  {  min-height: 64rem; }
  .minh9-ns  {  min-height: 96rem; }

  .minh-none-ns { min-height: none; }
}

@media #{$breakpoint-medium} {
  .minh-100-m  { min-height: 100%; }

  .minh1-m  {  min-height: 1rem; }
  .minh2-m  {  min-height: 2rem; }
  .minh3-m  {  min-height: 4rem; }
  .minh4-m  {  min-height: 8rem; }
  .minh5-m  {  min-height: 16rem; }
  .minh6-m  {  min-height: 32rem; }
  .minh7-m  {  min-height: 48rem; }
  .minh8-m  {  min-height: 64rem; }
  .minh9-m  {  min-height: 96rem; }

  .minh-none-m { min-height: none; }
}

@media #{$breakpoint-large} {
  .minh-100-l  { min-height: 100%; }

  .minh1-l  {  min-height: 1rem; }
  .minh2-l  {  min-height: 2rem; }
  .minh3-l  {  min-height: 4rem; }
  .minh4-l  {  min-height: 8rem; }
  .minh5-l  {  min-height: 16rem; }
  .minh6-l  {  min-height: 32rem; }
  .minh7-l  {  min-height: 48rem; }
  .minh8-l  {  min-height: 64rem; }
  .minh9-l  {  min-height: 96rem; }

  .minh-none-l { min-height: none; }
}
