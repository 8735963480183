
// Converted Variables


// Custom Media Query Variables


/*

  TABLES
  Docs: http://tachyons.io/docs/elements/tables/

*/

.collapse {
    border-collapse: collapse;
    border-spacing: 0;
}

.striped--light-gray:nth-child(odd) {
  background-color: $light-gray;
}
