
// Converted Variables


// Custom Media Query Variables


/*

   UTILITIES

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

/* Equivalent to .overflow-y-scroll */
.overflow-container {
  overflow-y: scroll;
}

.translateZ0 {
  transform: translateZ(0);
}

.transition-left {
  transition: left 0.2s ease;
}

.transition-opacity {
  transition: opacity 0.15s ease;
}

.transition-bg-color {
  transition: background-color 0.15s ease;
}

.center {
  margin-right: auto;
  margin-left: auto;
}

.hide {
  display:none;
}

.a11y-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.cols2 {
  columns: 2 16rem;
  column-gap: 1.5rem;
}

@media #{$breakpoint-not-small}{
  .center-ns {
    margin-right: auto;
    margin-left: auto;
  }
}

@media #{$breakpoint-medium}{
  .center-m {
    margin-right: auto;
    margin-left: auto;
  }
}

@media #{$breakpoint-large}{
  .center-l {
    margin-right: auto;
    margin-left: auto;
  }
}
