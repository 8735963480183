// @TODO: Consider cleaning this up a bit. Things to improve:
// - Remove all fonts that we don't use
//   (why are there two sarif and sans-sarif fonts?)
// - Remove literal names and instead use abstracted names
//   (Instead of $nunito-sans use $body-font or something else)
$sans-serif: -apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif;
$serif: georgia, times, serif;
$system-sans-serif: sans-serif;
$system-serif: serif;
$code: Consolas, monaco, monospace;
$courier: 'Courier Next', courier, monospace;
$nunito-sans: "Nunito Sans", -apple-system, BlinkMacSystemFont, "HelveticaNeue", "Helvetica", "Arial", sans-serif;

