
// Converted Variables


// Custom Media Query Variables


/*

  BOX-SHADOW
  Docs: http://tachyons.io/docs/themes/box-shadow/

  Media Query Extensions:
   -ns = not-small
   -m  = medium
   -l  = large

 */

.shadow-0 { box-shadow: none; }

.shadow-1 { box-shadow: $shadow-1; }
.shadow-2 { box-shadow: $shadow-2; }
.shadow-3 { box-shadow: $shadow-3; }
.shadow-4 { box-shadow: $shadow-4; }
.shadow-5 { box-shadow: $shadow-5; }

@media #{$breakpoint-not-small} {
  .shadow-1-ns { box-shadow: $shadow-1; }
  .shadow-2-ns { box-shadow: $shadow-2; }
  .shadow-3-ns { box-shadow: $shadow-3; }
  .shadow-4-ns { box-shadow: $shadow-4; }
  .shadow-5-ns { box-shadow: $shadow-5; }
}

@media #{$breakpoint-medium} {
  .shadow-1-m { box-shadow: $shadow-1; }
  .shadow-2-m { box-shadow: $shadow-2; }
  .shadow-3-m { box-shadow: $shadow-3; }
  .shadow-4-m { box-shadow: $shadow-4; }
  .shadow-5-m { box-shadow: $shadow-5; }
}

@media #{$breakpoint-large} {
  .shadow-1-l { box-shadow: $shadow-1; }
  .shadow-2-l { box-shadow: $shadow-2; }
  .shadow-3-l { box-shadow: $shadow-3; }
  .shadow-4-l { box-shadow: $shadow-4; }
  .shadow-5-l { box-shadow: $shadow-5; }
}
