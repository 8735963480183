h3.Header {
  font-size: map-get($type-scale, f2);
  font-weight: 400;
  margin: 1rem 0 2rem;
  line-height: 1;
  color: $dark-gray;
}

h4.Header {
  text-transform: uppercase;
  padding: 1rem;
  letter-spacing: $letter-spacing-subtle;
  font-weight: 800;
  color: $dark-gray;
}

.CardHeader {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
