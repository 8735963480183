// Converted Variables

$spacing-none: 0 !default; // 0
$spacing-extra-small-063: 0.063rem !default; // 1px
$spacing-extra-small-094: 0.094rem !default; // 1.5px
$spacing-extra-small-125: 0.125rem !default; // 2px
$spacing-extra-small: 0.25rem !default; // 4px
$spacing-small: 0.5rem !default; // 8px
$spacing-small2: 0.75rem !default; // 12px
$spacing-medium: 1rem !default; // 16px
$spacing-medium-125: 1.25rem !default; // 20px
$spacing-medium2: 1.5rem !default; // 24px
$spacing-medium3: 1.75rem !default; // 28px
$spacing-large: 2rem !default; // 32px
$spacing-large-25: 2.5rem !default; // 40px
$spacing-large2: 3rem !default; // 48px
$spacing-extra-large: 4rem !default; // 64px
$spacing-extra-large2: 6rem !default; // 96px
$spacing-extra-extra-large: 8rem !default; // 128px
$spacing-extra-extra-large2: 12rem !default; // 192px
$spacing-extra-extra-extra-large: 16rem !default; // 256px
$spacing-extra-large-20: 20rem !default; // 320px
$spacing-extra-large-25: 25rem !default; // 400px
$spacing-extra-large-40: 40rem !default; // 640px
$spacing-extra-large-45: 45rem !default; // 720px
$spacing-extra-large-65: 65rem !default; // 1040px
