// Converted Variables

// Custom Media Query Variables

/*

   LINE HEIGHT / LEADING
   Docs: http://tachyons.io/docs/typography/line-height

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.lh-solid {
  line-height: 1;
}
.lh-1 {
  line-height: $lh-1;
}
.lh-title {
  line-height: $lh-title;
}
.lh-copy {
  line-height: $lh-copy;
}
.lh-middle {
  line-height: $lh-middle;
}

@media #{$breakpoint-not-small} {
  .lh-solid-ns {
    line-height: 1;
  }
  .lh-title-ns {
    line-height: $lh-title;
  }
  .lh-copy-ns {
    line-height: $lh-copy;
  }
}

@media #{$breakpoint-medium} {
  .lh-solid-m {
    line-height: 1;
  }
  .lh-title-m {
    line-height: $lh-title;
  }
  .lh-copy-m {
    line-height: $lh-copy;
  }
}

@media #{$breakpoint-large} {
  .lh-solid-l {
    line-height: 1;
  }
  .lh-title-l {
    line-height: $lh-title;
  }
  .lh-copy-l {
    line-height: $lh-copy;
  }
}
