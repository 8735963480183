
// Converted Variables


// Custom Media Query Variables


/*

   BORDER STYLES
   Docs: http://tachyons.io/docs/themes/borders/

   Depends on base border module in _borders.css

   Base:
     b = border-style

   Modifiers:
     --none   = none
     --dotted = dotted
     --dashed = dashed
     --solid  = solid

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

 */

.b--dotted { border-style: dotted; }
.b--dashed { border-style: dashed; }
.b--solid {  border-style: solid; }
.b--none {   border-style: none; }

@media #{$breakpoint-not-small} {
  .b--dotted-ns { border-style: dotted; }
  .b--dashed-ns { border-style: dashed; }
  .b--solid-ns {  border-style: solid; }
  .b--none-ns {   border-style: none; }
}

@media #{$breakpoint-medium} {
  .b--dotted-m { border-style: dotted; }
  .b--dashed-m { border-style: dashed; }
  .b--solid-m {  border-style: solid; }
  .b--none-m {   border-style: none; }
}

@media #{$breakpoint-large} {
  .b--dotted-l { border-style: dotted; }
  .b--dashed-l { border-style: dashed; }
  .b--solid-l {  border-style: solid; }
  .b--none-l {   border-style: none; }
}
