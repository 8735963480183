
// Converted Variables


// Custom Media Query Variables


/*

   MAX WIDTHS
   Docs: http://tachyons.io/docs/layout/max-widths/

   Base:
     maxw = max-width

   Modifiers
     1 = 1st step in width scale
     2 = 2nd step in width scale
     3 = 3rd step in width scale
     4 = 4th step in width scale
     5 = 5th step in width scale
     6 = 6st step in width scale
     7 = 7nd step in width scale
     8 = 8rd step in width scale
     9 = 9th step in width scale

     -100 = literal value 100%

     -none  = string value none


   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

/* Max Width Custom */

.maxw-orgtext {
  max-width: calc((100vw - 23.375rem)*0.55);
}
.maxw-nametext {
  max-width: calc((100vw - 23.375rem)*0.45);
}

/* Max Width Percentages */

.maxw-100  { max-width: 100%; }

/* Max Width Scale */

.maxw1  {  max-width: 1rem; }
.maxw2  {  max-width: 2rem; }
.maxw3  {  max-width: 4rem; }
.maxw4  {  max-width: 8rem; }
.maxw4_25{ max-width: 10rem; }
.maxw4_5{  max-width: 12rem; }
.maxw5  {  max-width: 16rem; }
.maxw5_25{ max-width: 20rem; }
.maxw5_5{  max-width: 24rem; }
.maxw5_75{ max-width: 28rem; }
.maxw6  {  max-width: 32rem; }
.maxw6_25{ max-width: 36rem;}
.maxw6_5{  max-width: 40rem; }
.maxw7  {  max-width: 48rem; }
.maxw8  {  max-width: 64rem; }
.maxw8_5{  max-width: 76rem; }
.maxw9  {  max-width: 96rem; }

/* Max Width String Properties */

.maxw-none { max-width: none; }

@media #{$breakpoint-not-small} {
  .maxw-100-ns  { max-width: 100%; }

  .maxw1-ns  {  max-width: 1rem; }
  .maxw2-ns  {  max-width: 2rem; }
  .maxw3-ns  {  max-width: 4rem; }
  .maxw4-ns  {  max-width: 8rem; }
  .maxw5-ns  {  max-width: 16rem; }
  .maxw6-ns  {  max-width: 32rem; }
  .maxw7-ns  {  max-width: 48rem; }
  .maxw8-ns  {  max-width: 64rem; }
  .maxw9-ns  {  max-width: 96rem; }

  .maxw-none-ns { max-width: none; }
}

@media #{$breakpoint-medium} {
  .maxw-100-m  { max-width: 100%; }

  .maxw1-m  {  max-width: 1rem; }
  .maxw2-m  {  max-width: 2rem; }
  .maxw3-m  {  max-width: 4rem; }
  .maxw4-m  {  max-width: 8rem; }
  .maxw5-m  {  max-width: 16rem; }
  .maxw5_5-m{  max-width: 24rem; }
  .maxw6-m  {  max-width: 32rem; }
  .maxw7-m  {  max-width: 48rem; }
  .maxw8-m  {  max-width: 64rem; }
  .maxw9-m  {  max-width: 96rem; }

  .maxw-none-m { max-width: none; }
}

@media #{$breakpoint-large} {
  .maxw-100-l  { max-width: 100%; }

  .maxw1-l  {  max-width: 1rem; }
  .maxw2-l  {  max-width: 2rem; }
  .maxw3-l  {  max-width: 4rem; }
  .maxw4-l  {  max-width: 8rem; }
  .maxw5-l  {  max-width: 16rem; }
  .maxw5_25-l{ max-width: 20rem; }
  .maxw5_5-l{  max-width: 24rem; }
  .maxw6-l  {  max-width: 32rem; }
  .maxw6_5-l{  max-width: 40rem; }
  .maxw7-l  {  max-width: 48rem; }
  .maxw8-l  {  max-width: 64rem; }
  .maxw9-l  {  max-width: 96rem; }

  .maxw-none-l { max-width: none; }
}

@media #{$breakpoint-large2} {
  .maxw5_5-l2{  max-width: 24rem; }
}

@media #{$breakpoint-extra-large} {
  .maxw6_5-xl{  max-width: 40rem; }
}
