
// Converted Variables


// Custom Media Query Variables


/*

   FONT FAMILY GROUPS
   Docs: http://tachyons.io/docs/typography/font-family/

*/


.sans-serif {
  font-family: $sans-serif;
}

.serif {
  font-family: $serif;
}

.system-sans-serif {
  font-family: $system-sans-serif;
}

.system-serif {
  font-family: $system-serif;
}


/* Monospaced Typefaces (for code) */

/* From http://cssfontstack.com */
code, .code {
  font-family: $code;
}

.courier {
  font-family: $courier;
}


/* Sans-Serif Typefaces */

.nunito-sans {
  font-family: $nunito-sans;
}

